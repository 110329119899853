<template>
  <div>
    <div class="row mb-2">
      <div class="col-md-12">
        <router-link
          :to="{ name: 'admin.users.create' }"
          class="btn btn-primary btn-sm float-right"
          ><i class="lni lni-plus mr-2"></i> Add New</router-link
        >
        <h2 class="h4">User Management</h2>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row justify-content-between">
          <div class="col-md-4">
            <form @submit.prevent="fetch">
              <div class="form-group">
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search User"
                    aria-label="Search User"
                    aria-describedby="basic-addon2"
                    v-model="search.term"
                  />
                  <div class="input-group-append">
                    <button class="input-group-text" id="basic-addon2" type="submit"
                      ><i class="fa fa-search"></i
                    ></button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <select class="form-control" v-model="search.role" @change="fetch">
                <option value="0">All Users</option>
                <option value="1">Traders</option>
                <option value="2">Signal Subscribers</option>
                <option value="6">Learners</option>
                <option value="3">Guest Users</option>
                <option value="4">Affiliates</option>
                <option value="5">Moderators</option>
              </select>
            </div>
          </div>
        </div>
        <table class="table table-striped users-table">
          <thead>
            <tr>
              <th colspan="2">Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Role</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user, i) in users" :key="`user-${i}`">
              <td>
                <div>
                  <div data-v-0fd9bedc="" class="status online" v-if="userIsOnline(user)"></div>
                  <img :src="user.avatar" :alt="user.name" style="height: 40px; width: 40px; border-radius: 50%">
                </div>
              </td>
              <td>{{ user.name }} </td>
              <td>{{ user.email }}</td>
              <td>{{ user.phone_number }}</td>
              <td>{{ user.role }}</td>
              <td>
                <router-link v-if="user.role != 'admin'" :to="{ name: 'admin.users.show', params: { id: user.id }}">Details</router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      search: { 
        term: "", 
        role: this.$route.query.show ? this.$route.query.show : 0 
      },
      users: []
    };
  },

  methods: {
    fetch() {
      this.$loader.start()
      this.$axios
        .get(
          `/api/v1/admin/users?role=${this.search.role}&term=${this.search.term}`
        )
        .then((response) => {
          this.users = response.data.users;
          this.$loader.stop()
        });
    },

    userIsOnline(user) {
      return this.$store.getters.getOnlineUsers.filter(u => {
        return u.id == user.id
      }).length > 0
    }
  },

  computed: {
    show() {
      let show = 0

      switch (this.$route.query.show) {
        case 'traders':
          show = 1
          break;

        case 'signal-users':
          show = 2
          break;

        case 'moderators':
          show = 5
          break;

        case 'learners':
          show = 6
          break;
      
        default:
          show = 0
          break;
      }

      return show
    }
  }
};
</script>

<style scoped>
.users-table .status.off {
  border: 1px solid #5a5a5a;
  background: #ffffff;
}
.users-table .status.online {
  background: #4caf50;
}
.users-table .status.busy {
  background: #ffc107;
}
.users-table .status.offline {
  background: #ed4e6e;
}
.users-table .user .status {
  bottom: 0;
  left: 28px;
}
.users-table .status {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  position: absolute;
}
</style>